@font-face {
    font-family: "Poppins";
    font-weight: 700;
    font-style: normal;
    src: url("/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-weight: 600;
    font-style: normal;
    src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    src: url("/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: normal;
    src: url("/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-weight: 300;
    font-style: normal;
    src: url("/fonts/Poppins-Light.ttf") format("truetype");
}
