@import "~bootstrap/scss/bootstrap";
@import "./scss/fonts";

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px;
  scroll-behavior: smooth;
}
body {
  min-width: 1080px;
}
.label {
    font-size: 2rem !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
}

